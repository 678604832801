import api from '@/api/index'
// axios
import request from '@/utils/request'

/**
 * 查询banner图
 * @param data
 */
export function getBanberByVipLevel (data) {
  return request({
    url: api.GetBannerByVipLevel,
    method: 'get',
    params: data,
    hideloading: true
  })
}
