/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime (time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分'
  }
}

/**
 * 千位符 toThousands
 * num  字符串或者数字 *必传
 * fixed 保留位数 0整数 >=1小数位数  *不必填
 */
function toThousands (num, fixed) {
  num = num ? parseFloat(num.toString().replace(/,/g, '')) : 0.00
  num = (fixed ? num.toFixed(fixed) : (fixed === 0) ? Math.round(num) : num).toString().replace(/,/g, '')
  return num.replace(num.indexOf('.') < 0 ? /(\d)(?=(\d{3})+$)/g : /(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
    return $1 + ','
  })
}

/**
 * 数字类型过滤器
 * val  值
 * obj 过滤方式
 */
export function setIntFilter (val, obj = {}) {
  const initData = Object.assign({
    min: 0, // 最小值
    max: 99999999999, // 最大值
    fixed: -1, // 保留位数  -1不限制 0>=其他保留的位数
    default: '', // 默认值
    fixedSup: false, // 保留小数位数大于0时开启 小数位数不足是否补 0
    toThousands: false, // 是否转换千位符
    prefix: '', // 内容添加前缀
    suffix: '', // 内容添加后缀
    empty: '', // 数据为空时显示
    simplify: false, // 数字简化展示
    simplifyData: {
      0: {
        suffix: ''
      },
      10000: {
        suffix: '万'
      },
      100000000: {
        suffix: '亿'
      }
    },
    math: '' // round:保留小数四舍五入    ceil:保留小数向上取值   floor:保留小数向下取值 *使用时候请注意ceil与floor的使用与特性 Math.ceil(8.540*Math.pow(10,2))/Math.pow(10,2)
  }, obj)
  let simplifySuffix = ''
  let value = !isNaN(parseFloat(String(val).replace(/,/g, ''))) ? parseFloat(String(val).replace(/,/g, '')) : (!isNaN(parseFloat(String(initData.default).replace(/,/g, ''))) ? parseFloat(String(initData.default).replace(/,/g, '')) : '')
  const roundFun = function (value, n, type) {
    if (type === 'ceil') {
      const returnValue = Math.ceil(value * Math.pow(10, n)) / Math.pow(10, n)
      return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue
    } else if (type === 'floor') {
      const returnValue = Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)
      return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue
    } else {
      const returnValue = Math.round(value * Math.pow(10, n)) / Math.pow(10, n)
      return initData.fixedSup && n ? returnValue.toFixed(n) : returnValue
    }
  }
  if (typeof (value) === 'number') {
    /**
     * 初始值过滤判断
     */
    if (initData.min > value) {
      value = initData.min
    } else if (initData.max < value) {
      value = initData.max
    }
    /**
     * 简化展示
     */
    if (initData.simplify && initData.simplifyData) {
      let item = {}
      for (const num in initData.simplifyData) {
        if (num && value >= num) {
          item = initData.simplifyData[num]
          item.num = num
        }
      }
      simplifySuffix = item.suffix || ''
      value = value / item.num
    }
    /**
     * 保留位数转换
     */
    if (initData.fixed >= 0) {
      value = roundFun(value, initData.fixed, initData.math)
    }
    /**
     * 简化展示后缀
     */
    if (simplifySuffix) {
      value += simplifySuffix
    }

    /**
     * 转换千位符
     */
    if (initData.toThousands && String(value)) {
      value = toThousands(value)
    }
    /**
     * 新增前缀、后缀
     */
    if (String(value) && (initData.prefix || initData.suffix)) {
      value = initData.prefix + String(value) + initData.suffix
    } else if (initData.empty && !String(value)) {
      value = initData.empty
    }
  }
  return value || initData.empty
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj (url) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')
      .replace(/\+/g, ' ') +
    '"}'
  )
}

/**
 * 计算一个字符串在另一个字符串中包含几次
 * @param thisForm
 * @returns {*}
 */
export function stringFre (subString, originString, isIgnoreCap = false) {
  if (!subString || !originString) return 0
  let str
  if (isIgnoreCap) {
    str = `/${subString}/g`
  } else {
    str = `/${subString}/gi`
  }
  const reg = evil(str)
  if (!originString.match(reg)) return 0
  return originString.match(reg).length
}

export function evil (fn) {
  const Fn = Function
  return new Fn('return ' + fn)()
}

export function formTrim (thisForm) {
  if (!thisForm) {
    return thisForm
  }
  for (const key in thisForm) {
    thisForm[key] = (thisForm[key] && thisForm[key].constructor === String) ? thisForm[key].trim() : thisForm[key]
  }
  return thisForm
};

export function conversionNumIndex (obj) {
  const objs = String(obj)
  if (!obj || objs.indexOf('--') !== -1 || objs.indexOf('万') !== -1 || objs.indexOf('亿') !== -1 || objs.indexOf('%') !== -1) {
    return obj
  }
  let returnValue = ''
  const num = obj * 1
  const wan = num / 10000
  const yi = num / 100000000
  returnValue = num
  let unit = ''
  if (Math.abs(wan) > 1) {
    unit = '万'
    returnValue = wan
  }
  if (Math.abs(yi) > 1) {
    unit = '亿'
    returnValue = yi
  }
  return returnValue.toFixed(2) + unit
};
