<template>
  <div class="page-component__scroll">
    <div class="img-content">
      <el-image style="height: 600px;width: 100%;" draggable="false" fit="fill" :src="bg"></el-image>
      <index-search @loginEmit="loginEmit" class="index-search-content"></index-search>
    </div>
    <index-swiper></index-swiper>
    <tabulate-data></tabulate-data>
    <index-achievement-statistical-map></index-achievement-statistical-map>
    <index-achievement-list></index-achievement-list>
    <!-- <app-link></app-link> -->
    <new-app-link></new-app-link>
  </div>
</template>

<script>
// import AppLink from '@/components/app-link/app-link'
import NewAppLink from '@/components/app-link/new-app-link'
import IndexSwiper from '@/components/index-swiper/index-swiper'
import IndexSearch from '@/components/index-search/index-search'
import TabulateData from '@/components/tabulate-data/tabulate-data'
import IndexAchievementList from '@/components/index-achievement-list/index-achievement-list'
import IndexAchievementStatisticalMap from '@/components/index-achievement-statistical-map/index-achievement-statistical-map'
// import Vue from 'vue'
export default {
  setup (props, context) {
    function loginEmit () {
      context.emit('loginEmit', 'company')
    }
    return {
      loginEmit
    }
  },
  name: 'index',
  components: { TabulateData, IndexAchievementList, IndexAchievementStatisticalMap, IndexSearch, IndexSwiper, NewAppLink },
  data () {
    return {
      bg: require('../../assets/index/bg.png')
    }
  },
  created () {},
  mounted () {},
  methods: {
  },
  watch: {
    $route: {
      handler (val, oldval) {
        console.log(val)
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.img-content {
  margin-top: -60px;
  min-width: 1200px;
  position: relative;
  .index-search-content {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-left: -600px;
  }
}
</style>
