<template>
  <div>
    <el-row>
      <el-col :span="24" style="margin-bottom: 65px;margin-top: 60px;">
        <div class="container dis-cen-cen">
          <el-divider content-position="center" style="width: 1000px;">
            <div style="background-color: #f1f2f7;padding: 0 20px;">
              <div class="main-title">数据采集来自政府相关部门</div>
              <div class="sub-title">央企一手数据权威信息来源</div>
            </div>
          </el-divider>
        </div>
      </el-col>
    </el-row>
    <div class="container dis-aro-cen" style="margin-bottom: 110px;">
      <div class="td-item">
        <span class="td-value"><strong><countTo :startVal='startVal' :endVal='companyCount' :duration='3000' :decimals="2"></countTo></strong></span><span class="td-units"> 万家</span><br>
        <span class="td-caption">企业信息</span>
      </div>
      <div class="td-item">
        <span class="td-value"><strong><countTo :startVal='startVal' :endVal='allNum' :duration='3000' :decimals="2"></countTo></strong></span><span class="td-units"> 万条</span><br>
        <span class="td-caption">累计数据</span>
      </div>
      <div class="td-item">
        <span class="td-value"><strong><countTo :startVal='startVal' :endVal='164' :duration='3000' :decimals="0"></countTo></strong></span><span class="td-units"> 个</span><br>
        <span class="td-caption">维度数据</span>
      </div>
      <div class="td-item">
        <span class="td-value">
          <strong v-if="lastThreeDaysNewCompany"><countTo :startVal='startVal' :endVal='lastThreeDaysNewCompany' :duration='3000' :decimals="2"></countTo></strong>
          <strong v-else>0</strong>
        </span><span class="td-units">万</span><br>
        <span class="td-caption">今日新增企业</span>
      </div>
      <div class="td-item">
        <span class="td-value">
          <strong v-if="lastThreeDaysNewAchievement"><countTo :startVal='startVal' :endVal='lastThreeDaysNewAchievement' :duration='3000' :decimals="2"></countTo></strong>
          <strong v-else>0</strong>
        </span><span class="td-units"> 万条</span><br>
        <span class="td-caption">今日新增中标</span>
      </div>
    </div>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
import { setIntFilter } from '@/utils/index'
import countTo from '@/components/vue-count-to/vue-countTo' // 滚动的数字
export default {
  name: 'tabulate-data',
  components: { countTo },
  data () {
    return {
      companyCount: 0,
      allNum: 0,
      lastThreeDaysNewAchievement: 0,
      lastThreeDaysNewCompany: 0
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData: function () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/dataScreen/newGetDataScreen',
        dateBackgroundRequestType: 'get'
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const data = result.data
          vm.companyCount = setIntFilter(data.companyCount ? data.companyCount / 10000 : 0, { fixed: 2, empty: 0 })
          vm.allNum = setIntFilter(data.allNum, { fixed: 2, empty: 0 })
          vm.lastThreeDaysNewAchievement = setIntFilter(data.lastThreeDaysNewAchievement, { fixed: 2, empty: 0 })
          vm.lastThreeDaysNewCompany = setIntFilter(data.lastThreeDaysNewCompany, { fixed: 2, empty: 0 })
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped">
.main-title {
  font-size: 24px;
  color: #333333;
}
.sub-title {
  color: #333333;
  font-size: 14px;
}
.td-item .td-value {
  color: #5D6FE9;
  font-size: 48px;
}
.td-item .td-caption {
  font-size: 18px;
  text-align: left;
  display: block;
  margin-left: 10px;
}
.td-item .td-units {
  color: #5D6FE9;
  font-size: 24px;
}
</style>
