<template>
  <div v-if="imgList.length > 0" style="margin: 70px 0;">
    <div class="container">
      <el-carousel height="120px" indicator-position="none" :interval="5000" arrow="always">
        <el-carousel-item v-for="(item, index) in imgList" :key="index">
         <el-image draggable="false" fit="contain" :src="item.picUrl" @click="imageClick(item)"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getBanberByVipLevel } from '@/api/index/index'
export default {
  name: 'index-swiper',
  data () {
    return {
      imgList: []
    }
  },
  created () {},
  computed: {
    // vuex获取值
    // this.$store.user.vipData = obj
    ...mapGetters([
      'vipData' // 会员数据
    ])
  },
  mounted () {
    const vm = this
    // 初始化banner图
    vm.initIndexSwiperData()
  },
  watch: {
    vipData (newVal) {
      const vm = this
      vm.initIndexSwiperData()
    }
  },
  methods: {
    initIndexSwiperData () {
      const vm = this
      const data = {
        vipLevel: vm.vipData?.vipLevel ? vm.vipData.vipLevel : '00010001',
        bannerLocation: 4
      }
      getBanberByVipLevel(data).then(result => {
        if (result.code === 0) {
          const data = result.data ? result.data : []
          this.imgList = data
        }
      }).catch(error => {
        console.log(error)
      })
    },
    imageClick (obj) {
      const vm = this
      if (obj.bannerType === 0) {
        // 详情介绍
        window.open(obj.jumpUrl)
      } else if (obj.bannerType === 1) {
        // 跳转页面
        vm.$router.push({ name: obj.jumpUrl })
      }
    }
  }
}
</script>

<style scoped="scoped" lang="less">
</style>
