<template>
  <div class="link-content">
    <div class="container">
      <div class="link-title-header">
        <div class="link-title-name" :class="active == index ? 'active' : ''" @mousemove="handleChange(index)" v-for="(item, index) of list" :key="index">{{item.name}}</div>
      </div>
      <div class="line-content-tips" v-for="(item, index) of list" :key="index" v-show="index == active">
        <div class="line-tips-title">
          <img :src="item.logo" :alt="item.name" style="vertical-align: middle;height: 76px;">
        </div>
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 60px 40px;position: relative;">
          <div class="link-left">
            <div class="link-left-names">{{item.name}}</div>
            <div class="link-left-tips" v-if="index != 0 && index != 1">{{item.tips}}</div>
            <div class="link-left-tips" style="position: relative;" v-else-if="index == 1">
            <!-- <p style="color: red;font-weight: bold;position: absolute;top: -35px;">『 首次授信成功：享7.5折利息券，活动时间:即日起至2024年3月31日。』</p> -->
            <p> <span style="font-weight: bold;color: #333;">1.额度：</span>最高<span style="color: red;">20万元</span>，可循环使用。</p>            <p> <span style="font-weight: bold;color: #333">2.利率：</span>年化<span style="color: red;">7.2%~18%（单利）</span></p>            <p> <span style="font-weight: bold;color: #333">3.期限：</span>授信期限1年；单笔支用期限最长1年支用期限可分别为3个月、6个月及12个月。</p>            <p> <span style="font-weight: bold;color: #333">4.还款：</span>随借随还，未支用不计息，提前还款无手续费。</p>            <p> <span style="font-weight: bold;color: #333">5.还款方式：</span>等额本息</p>
            </div>
            <div class="link-left-tips" style="position: relative;" v-else-if="index == 0">
            <!-- <p style="color: red;font-weight: bold;position: absolute;top: -35px;">『 首次授信成功：享7.5折利息券，活动时间:即日起至2024年3月31日。』</p> -->
            <p> <span style="font-weight: bold;color: #333;">1.授信额度：</span><span style="color: red;">5万-200万元</span></p>
            <p> <span style="font-weight: bold;color: #333">2.授信期限：</span>3 / 6 / 12个月</p>
            <p> <span style="font-weight: bold;color: #333">3.利率：</span>年化利率<span style="color: red;">5.4%-18%（单利）</span></p>
            <p> <span style="font-weight: bold;color: #333">4.特点：</span>纯线上操作、按日计息、提前还款无违约金</p>
            <!-- <p> <span style="font-weight: bold;color: #333">5.开放区域：</span>全国范围，排除以下区域：新疆、西藏、黑龙江、吉林、辽宁、港澳台 </p> -->
            </div>
          </div>
          <div class="link-right">
            <img :src="item.img" style="width: 150px;" />
          </div>
          <div class="link-left-bg">0{{index + 1}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'new-app-link',
  data () {
    return {
      active: 0,
      list: [
        {
          name: '万家贷-商贷',
          logo: require('../../assets/index/linklogo1.png'),
          tips: '万家贷-秒贷是一个全国性的个人综合消费贷款平台，为广大用户提供最高20万元的贷款额度。我们承诺年化利率最低7.2%，并且用户可以随时随地进行借款和还款，24小时不间断服务。欢迎来体验万家极速贷，让我们一起满足您的资金需求，助力您的生活发展！',
          img: require('@/assets/index/linklogo0.png')
        },
        // {
        //   name: '万家贷-秒贷',
        //   logo: require('../../assets/index/linklogo1.png'),
        //   tips: '万家贷-秒贷是一个全国性的个人综合消费贷款平台，为广大用户提供最高20万元的贷款额度。我们承诺年化利率最低7.2%，并且用户可以随时随地进行借款和还款，24小时不间断服务。欢迎来体验万家极速贷，让我们一起满足您的资金需求，助力您的生活发展！',
        //   img: require('@/assets/images/wjjsd.png')
        // },
        {
          name: '工程企信贷',
          logo: require('../../assets/index/linklogo2.png'),
          tips: '“工程企信贷”是易信信用评级服务在工程行业的落地应用场景，是易信联合中国邮政储蓄银行，响应国务院号召，为解决工程领域中小微企业融资难、融资贵的困难，推出的特色金融服务的人工智能产品。',
          img: require('@/assets/images/qr_gcqxd.png')
        },
        {
          name: '国行信e融',
          logo: require('../../assets/index/linklogo4.png'),
          tips: '国行信e融业务依托全国信易贷示范平台，以国家信用大数据创新中心的公开交易数据为基础，结合公检法、征信等数据，通过建立中小企业信用评价和风险管理模型，实现客户授信自动准入、自动核额。',
          img: require('@/assets/images/qr_ghxyr.png')
        },
        {
          name: '工程好人贷',
          logo: require('../../assets/index/linklogo3.png'),
          tips: '“工程好人贷” 为易信公司与新网银行联合打造，是国内首款针对工程行业从业人员的金融产品。“工程好人贷” 主要面向工程行业企业主、股东、高管及持证人员。',
          img: require('@/assets/images/qr_gchrd.png')
        }
      ]
    }
  },
  methods: {
    handleChange (e) {
      this.active = e
    }
  }
}
</script>

<style scoped>
  .link-content {
    background-image: url("../../assets/index/linkbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    height: 644px;
    margin-top: 60px;
  }
  .link-title-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #EBEBEB;
    padding: 15px 0;
    margin-bottom: 40px;
  }
  .link-title-name {
    cursor: pointer;
    position: relative;
    width: 200px;
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
  .active {
    color: #5D6FE9;
  }
  .active::after {
    content: "";
    background: #5D6FE9;
    width: 60px;
    height: 3px;
    position: absolute;
    left: 50%;
    margin-left: -30px;
    bottom: -16px;
  }
  .line-content-tips {
    height: 506px;
    box-shadow: 2px 5px 5px 5px rgba(235, 235, 235, 0.5);
    background: #fff;
  }
  .line-tips-title {
    height: 100px;
    border-bottom: 1px solid #EBEBEB;
    text-align: left;
    padding: 0 40px;
    line-height: 100px;
    align-items: center;
  }
  .link-left {
    width: 70%;
    text-align: left;
    position: relative;
  }
  .link-right {
    width: 30%;
    text-align: center;
  }
  .link-right img{
    box-shadow: 2px 2px 5px 2px rgba(211, 208, 208, 0.5);
    padding: 20px;
  }
  .link-left-names {
    font-size: 28px;
    margin-bottom: 60px;
    position: relative;
  }
  .link-left-names::after {
    content: "";
    background: #5D6FE9;
    width: 80px;
    height: 5px;
    position: absolute;
    left: 0;
    bottom: -15px;
  }
  .link-left-tips {
    color: #999999;
    line-height: 36px;
    padding-right: 70px;
  }
  .link-left-bg {
    position: absolute;
    font-size: 260px;
    color: #999;
    top: 0;
    left: 30px;
    opacity: 0.1;
    font-weight: 600;
  }
</style>
