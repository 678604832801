<template>
  <div style="margin-top: 80px; width: 100%;overflow: hidden;">
    <el-row>
      <div class="container dis-bet-sta">
        <el-col :span="24" >
          <el-row style="margin-bottom: 20px;">
            <el-col :span="12">
              <div class="tableTopLeft" style="font-size: 18px;color: #333333;">行业分析报告</div>
            </el-col>
            <el-col :span="12">
              <div class="tableTopRight" @click="handleToReport()" style="font-size: 16px;color: #999999;cursor:pointer;">查看更多></div>
            </el-col>
          </el-row>
          <el-card class="box-card" style="height: 510px;padding: 10px 0;">
              <el-row :gutter="15">
                <el-col style="cursor: pointer;" :span="12" v-for="(item, index) in achievementList" :key="index" @click="handleToPdf(item.file)">
                  <el-row :gutter="10" >
                    <el-col :span="6">
                      <el-image style="height:90px;width:120px;border: 1px solid #E6E6E6;"
                                :src="item.cover"
                                @error="handleError($event)"
                                fit="contain">
                      </el-image>
                    </el-col>
                    <el-col :span="18" style="display: flex;align-content: space-between;">
                      <el-row class="dis-sta-cen">
                        <el-col :span="24">
                          <div class="tableLeftOne divOmit" style="font-size: 16px;color: #333333;">{{ item.title }}</div>
                        </el-col>
                        <el-col :span="24">
                          <div v-if="item.reportType === '0'" class="tableLeftTwo divOmit" style="font-size: 12px;color: #999999;">类型：平台信用</div>
                          <div v-if="item.reportType === '1'" class="tableLeftTwo divOmit" style="font-size: 12px;color: #999999;">类型：月度活跃排名</div>
                        </el-col>
                        <el-col :span="24">
                          <div class="tableLeftThree divOmit" style="font-size: 14px;color: #666666;">{{ item.describe }}</div>
                        </el-col>
                      </el-row>
                    </el-col>
                  </el-row>
                  <br v-if="index < achievementList.length - 1">
                </el-col>
              </el-row>
          </el-card>
        </el-col>
        <!-- <el-col :span="12" style="height: 100%;">
          <el-row style="margin-bottom: 20px;">
            <el-col :span="12">
              <div class="tableTopLeft" style="font-size: 18px;color: #333333;">最新中标公告</div>
            </el-col>
            <el-col :span="12">
              <div @click="handleToAchievement()" class="tableTopRight" style="font-size: 16px;color: #999999;cursor:pointer;">查看更多></div>
            </el-col>
          </el-row>
          <el-card shadow="always" class="box-card" style="height: 500px;">
            <vue-seamless-scroll :data="achievementListNew" class="seamless-warp">
            <div style="cursor: pointer;" class="list-hover" v-for="(item, index) in achievementListNew" :key="index">
              <el-row >
                <el-col :span="24">
                  <el-row style="margin-top: 10px;">
                    <el-col :span="24"><div class="tableLeftOne divOmit" style="font-size: 16px;color: #333333;">{{ item.projectName }}</div></el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <div class="tableLeftTwo divOmit" style="font-size: 12px;color: #999999;">{{ item.province }} {{ item.city }}</div>
                    </el-col>
                  </el-row>
                  <el-row style="margin-top: 10px;">
                    <el-col :span="12">
                      <div class="tableLeft" style="text-align: left;font-size: 14px;color: #666666;">{{ item.companyName }}</div>
                    </el-col>
                    <el-col :span="12">
                      <div class="tableLeft" style="text-align: right;font-size: 12px;color: #999999;">{{ item.timeSuccess }}</div>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            </vue-seamless-scroll>
          </el-card>
        </el-col> -->
      </div>
    </el-row>
  </div>
</template>

<script>
import { getActiveRankingList } from '@/api/forward.js'
// 首页里面的中标列表
export default {
  name: 'index-achievement-list',
  data () {
    return {
      achievementList: [],
      achievementListNew: [],
      boxLeftHeight: 400
    }
  },
  created () {
  },
  mounted () {
    const vm = this
    document.οncοntextmenu = function (e) {
      return false
    }
    vm.initAchievement()
    vm.initAchievementNew()
  },
  methods: {
    initAchievement () {
      const vm = this
      const data = {
        // dateBackgroundUrl: '/activeRanking/getActiveRankingList',
        // dateBackgroundRequestType: 'get',
        // data: {
        //   reportType: '1',
        //   pageNum: 1,
        //   pageSize: 8
        // },
        reportType: '1',
        pageNum: 1,
        pageSize: 8
      }
      getActiveRankingList(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.achievementList = res.list
        }
      }).catch(error => {
        console.log(error)
      })
    },
    initAchievementNew () {
      const vm = this
      for (let i = 0; i < 10; i++) {
        vm.achievementListNew.push({
          projectName: '东明县沙窝镇段庄蔡寨八里寺村2020年度财政专项扶贫开发项目（第二批）', // 项目名称
          province: '吉林省', // 省
          city: '长春市', // 市
          companyName: '吉林省华创物联网', // 公司名
          timeSuccess: '2020-09-18' // 中标时间
        })
      }
    },
    handleToPdf: function (e) {
      window.open(e + '#toolbar=0', '_self')
    },
    // 图片加载失败
    handleError (e) {
      e.currentTarget.src = require('@/assets/images/no_image.png')
    },
    handleToAchievement () {
      const vm = this
      vm.$router.push({
        name: 'company-achievement'
      })
    },
    handleToReport () {
      const vm = this
      vm.$router.push({
        name: 'company-achievement-report'
      })
    }
  }
}
</script>

<style scoped="scoped">
.tableTopLeft {
  float: left;
  position: relative;
  padding-left: 10px;
}
.tableTopLeft::before{
  content: '';
  position: absolute;
  width: 2px;
  height: 15px;
  background-color: #3967FF;
  top: 6px;
  left: 0;
}
.tableTopRight{
  float: right;
}
.tableTopRight a{
  color: #000;
  text-decoration:none
}
.tableImage{
  width: 160px;
  height: 90px;
}
.divOmit{
  text-align: left;
  width:100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tableLeftOne{}
.tableLeftTwo{}
.tableLeftThree{}
.el-row {
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap
}
.list-hover{
  padding: 10px;
  border-bottom: 1px solid #ebebeb;
}
.list-hover:hover{
  background-color: #F3F9FD;
}
</style>
